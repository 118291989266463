import loadable from "@loadable/component"
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
const OnfidoVerification = loadable(
  () => import("../auth/sign-up/components/OnfidoVerification")
)

import { useUser } from "../../src/auth/hooks"
import { LinkButton, PageGuard, Loader } from "../components"

const Legal = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 15px;
  position: absolute;
  z-index: 99;
`

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OnfidoPage: React.FC = () => {
  const { user, loading } = useUser()
  const [VerificationComponent, setVerificationComponent] = useState<
    typeof OnfidoVerification | null
  >(null)
  const [yocoUser, setYocoUser] = useState<Reseller.OnfidoFormattedUser | null>(
    null
  )

  useEffect(() => {
    if (!loading && user) {
      setYocoUser({
        uuid: user.pk,
        first_name: user.firstName,
        last_name: user.lastName,
        id_number: user.profile?.idNumber,
        passport_number: user.profile?.passportNumber,
        mobile_number: user.mobileNumber,
      })
    }
  }, [user, loading])

  useEffect(() => {
    const loadVerificationComponent = async () => {
      setVerificationComponent(OnfidoVerification)
    }

    if (typeof window !== "undefined") {
      loadVerificationComponent()
    }
  }, [])

  return (
    <PageGuard>
      {yocoUser !== null && VerificationComponent ? (
        <VerificationComponent
          user={yocoUser}
          open={true}
          onClose={() => navigate("/dashboard/")}
        />
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <Legal>
        <LinkButton
          to="https://www.yoco.com/za/articles/reseller-legal-explainer/"
          target="_blank"
          color="#FF9E00"
          padding="10px 10px 10px 0px"
        >
          Why we require these documents
        </LinkButton>
      </Legal>
    </PageGuard>
  )
}

export default OnfidoPage
